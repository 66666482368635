.page {
  height: calc(100vh - var(--header_height));
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  overflow: hidden;
  // background: white;
}

.Image {
  flex: 1 0 0;
  max-height: 600px;
  margin: 20px auto 48px;
  object-fit: contain;
  overflow: hidden;
}

.goHomeBtn {
  width: 100px;
  padding: 12px 0;
  margin-bottom: 64px;

  background: var(--color-primary);
  border: none;
  border-radius: 50px;

  cursor: pointer;

  & > a {
    font-size: 18px;
    color: white;
    text-decoration: none;
  }
}
