.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 340px;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 20px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 10px var(--color-shade);
}

.title {
  margin: 0 auto 32px;
}

.description {
  max-width: 300px;
  margin-top: 12px;
}

.formItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.formItemLabel {
  margin-bottom: 6px;
  font-weight: 700;
}

.forgotPassword {
  margin-top: 4px;
  font-size: 12px;
  text-decoration: none;
  color: var(--color-primary);
}

.loginBtn {
  padding: 12px 0;
  margin: 24px 0 12px;
  text-transform: uppercase;
}

.error {
  padding: 6px 0;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid red;
  border-radius: 4px;
  text-align: center;
  color: red;
}
