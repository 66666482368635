.sliderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}


.title {
  color: rgb(85, 81, 81);
  margin-bottom: 8px;
}
