.page {
  width: 100%;
}

.form {
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 32px;

  background: white;
  border: var(--border);
  border-radius: 6px;
}

.formField {
  width: 100%;
  display: grid;
  grid-template-columns: 170px 1fr;
  align-items: center;
  margin-bottom: 32px;

  & > :last-child {
    width: 100%;
    max-width: unset !important;
  }
}