.container {
  width: 100%;
  display: flex;
  overflow: hidden;

  background: var(--product-background_card);
  border: var(--border);
  border-radius: 6px;
  cursor: pointer;
}

.picture {
  width: 240px !important;
  height: 120px !important;
  flex-shrink: 0;
  object-fit: contain;
  margin: 16px;
  border-radius: 4px;
}

.gallery {
  width: 240px !important;
  height: 120px !important;
  flex-shrink: 0;
  background: white;
}

.info {
  width: 100%;
  display: block;
  padding: 16px 16px 8px;

  & :nth-child(n) {
    color: var(--color-primary_text);
  }

  @media (min-width:768px) {
    display: flex;
  }
}

.firstCol {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 36px;
}

.name {
  margin-bottom: auto;
  font-weight: bold;
}

.firmAvatar {
  height: 24px;
  object-fit: contain;
  object-position: left;
  margin: 8px 0 6px;
}

.firmName {
  white-space: nowrap;
  margin-bottom: 4px;

  @media (min-width:768px) {
    margin-bottom: 0;
   }
}

.params {
  margin-right: 24px;

  & :nth-child(n) {
    margin-bottom: 8px;
  }
}

.lastCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;

  @media (min-width:768px) {
    align-items: flex-end;

  }
}

.price {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;

  &>p {
    width: 100%;
    text-align: left;
    @media (min-width:768px) {
      text-align: right;
    }
  }
  @media (min-width:768px) {
   margin-bottom: 0;
  }
}

.unsubBtn {
  width: max-content !important;
  padding: 0 !important;
  margin-top: auto;
  opacity: 0.6;

  &:hover {
    opacity: 1;
    color: var(--color-red) !important;
  }
}