.header {
  width: 100%;
  height: var(--header_height);
  background: #fff;
  box-shadow: 0 0 16px -6px var(--color-shade);
  transition: all 0.5s ease;
}

.nav {
  width: 100%;
  height: 100%;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin: 0 auto;
}

.logo {
  width: 36px;
  height: 36px;
  margin-right: 32px;
  object-fit: contain;
  cursor: pointer;
}

.link {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;

  // border-radius: 6px;
  text-decoration: none;
  margin: 0 5px;
}

.activeLink,
.link:hover {
  background: var(--color-primary);
  color: var(--color-primary_text);
}

.shoppingCartBtn {
  height: 24px;
  display: none;
  cursor: pointer;
  text-decoration: none;

  &>svg {
    height: 100%;
  }

  @media (min-width: 460px) {
    display: flex;
  }
}

.profileBtnContainer {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 24px;
  position: relative;
}

.profileBtn {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: none;
  border: none;

  font-size: 10px;

  cursor: pointer;
}

.avatar {
  width: 36px;
  height: 36px;
  object-fit: cover;
  // margin-right: 8px;

  background: var(--color-background);
  border-radius: 20px;
}

.profileBtnBox {
  display: none;

  @media (min-width: 400px) {
    display: flex;
    width: 100%;
  }
}

.links{
  display: none;
  @media (min-width: 400px) {
    display: flex;
    height: 100%;
  }
}

.hamburgerMenuBox {
  display: flex;
  width: 100%;

  @media (min-width: 400px) {
    display: none;

  }
}

.profileBtnMenu {
  width: 260px;
  display: flex;
  flex-direction: column;
  text-align: right;
  overflow: hidden;

  position: absolute;
  bottom: -12px;
  right: -8px;
  transform: translateY(100%);
  z-index: 1;

  background: white;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  &> :nth-child(n) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    font-size: 18px;
    text-decoration: none;

    &> :first-child {
      width: 22px;
      height: 22px;
      object-fit: contain;
      object-position: center;
      margin-right: 16px;
    }

    &:hover {
      background: var(--color-background-light_gray);
      color: var(--color-primary);
    }
  }
}

.avatarInMenuContainer {
  width: 34px !important;
  height: 34px !important;
  overflow: hidden;
  margin-left: -4px;


  border-radius: 20px;

  &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}




.hamburgerLines {
  display: block;
  margin-left: auto;
  height: 36px;
  // height: 20px;
  width: 25px;
  // top: 17px;
  // right: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 8px;
  padding-top: 8px;

  background: none;
  border: none;
}


.line {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background: var(--color-primary);
  opacity: 0.8;
}

.line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.line2 {
  transition: transform 0.2s ease-in-out;
}

.line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.openLine1 {
  transform: rotate(45deg);
}

.openLine2 {
  transform: scaleY(0);
}

.openLine3 {
  transform: rotate(-45deg);
}