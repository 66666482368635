.container {
  display: flex;
  padding: 6px;
  position: relative;

  background-color: white;
  border: var(--border);
  border-radius: 4px;

  font-size: 16px;
}

.container:hover, .focus {
  box-shadow: 0 0 4px var(--color-border);
}

.containerError {
  border-color: red !important;
}

.input {
  width: 100%;
  border: none;

  &:focus {
    outline: none;
  }
}

.prefix {
  margin-right: 6px;
  cursor: default;
  pointer-events: none;
}

.error {
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);

  font-size: 14px;
  color: red;
}