.page {
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    "m"
    "g"
    "f";

  @media (min-width: 960px) {
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: max-content;
    grid-template-areas:
      "m g"
      "f .";
  }

  ;
}

.block {
  padding: 24px;
  background: rgba(255, 255, 255, 0.5);
  border: var(--border);
  border-radius: 6px;
}

.mainChartBlock {
  width: 100%;
  height: max-content;
  grid-area: m;
  display: flex;
  flex-direction: column;
}

.mainChart {
  // width: 100%;
  margin: 0 -24px -24px;
}

.goalsBlock {
  grid-area: g;
}

.fundsTable,
.goalsTable {
  width: 100%;
  border-collapse: collapse;

  & td {
    padding: 12px 0;
    border-top: var(--border);
    border-bottom: var(--border);
  }
}

.goalsModalContainer {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  background: var(--color-background);
}

.goalsInputContainer {
  margin-bottom: 16px;
}

.error {
  color: var(--color-red);
}

.goalThreeDotsPopup {
  position: absolute;
  bottom: 8px;
  right: 0;
  z-index: 1;
  transform: translateY(100%);
  overflow: hidden;

  background: white;
  border: var(--border);
  border-radius: 4px;

  &>button {
    width: 100%;
    padding: 6px 12px;
    text-align: left;

    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      background: var(--color-primary);
      color: white;
    }
  }
}

.ghostBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.historyBlock {
  grid-area: h;
}

.foundsBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-area: f;

  @media (min-width: 700px) {
    align-items: unset;
    // flex-direction: row;
  }
}

.fundsTable {
  width: 100%;
  min-width: 300px;
  height: max-content;
}