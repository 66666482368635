.input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.checkbox {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  background: white;
  border: var(--border);
  border-radius: 2px;

  cursor: pointer;
  
  &:hover{
    border: 1px solid var(--color-primary); 
  }
}

.indeterminate {
  width: 70%;
  height: 70%;
  display: block;

  background: var(--color-primary);
  border-radius: 2px;
}

.checked {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);

  & > svg {
    width: 12px;
    height: 12px;
    object-fit: contain;
    stroke-width: 4;
  }
}