.container {
  width: 100%;
}

.title {
  margin-bottom: 16px;
}

.productsContainer {
  width: 100%;
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.noDataMessage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
