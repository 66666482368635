.inputContainer {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.title {
  width: 100px;
  color: rgb(85, 81, 81);
  margin: 0 8px 0px 0px;
}

.input {
  width: 100%;
  max-width: 200px;
  padding: 6px 10px;
  outline: none;

  background: var(--color-background-light_gray);
  border: none;
  border-radius: 4px;
  font-size: 16px;


  color: rgb(63, 62, 62);

  &:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}
