.container {
  padding: 6px;
  display: flex;
  margin-bottom: 24px;


  background: white;
  border: var(--border);
  border-radius: 6px;
}

.categories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.minimize {
  flex-wrap: nowrap;
  overflow: hidden;
}

.categoryBtn {
  padding: 6px 10px;
  margin-right: 2px;

  background: none;
  border: none;
  white-space: nowrap;
  cursor: pointer;
}

.activeCategory, .categoryBtn:hover {
  background: var(--color-primary);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: white;
}

.allBtn {
  height: max-content;
  align-self: flex-end;
  padding: 6px;

  position: relative;
  z-index: 0;

  background: white;
  border: none;
  cursor: pointer;

  color: var(--color-primary);
  font-weight: 700;

  &:before {
    content: '';
    width: 40px;
    height: 100%;
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-90%);

    background: linear-gradient(to right, transparent 0%, white 60%);
    z-index: -1;
  }
}
