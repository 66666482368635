.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  background: rgba(0, 0, 0, 0.6);
}

.content {
  display: contents;
}
