.container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

.container:hover {
  .fullscreenBtn, .arrow, .bullets {
    opacity: 1;
  }
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  background: black;
}

.children {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  & > :nth-child(n) {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.fullscreenBtn {
  width: 32px;
  height: 32px;

  position: absolute;
  top: 16px;
  right: 16px;

  opacity: 0;
  background: none;
  border: none;
  cursor: pointer;

  transition: transform 0.1s, opacity 0.3s;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(0 0 2px black);
  }

  &:hover {
    transform: scale(1.1);
  }
}

.arrow {
  width: 60px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 8px;

  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  opacity: 0;
  background: none;
  border: none;
  cursor: pointer;

  transition: transform 0.1s, opacity 0.3s;

  & > img {
    width: 20px;
    height: auto;
    object-fit: contain;
    filter: drop-shadow(0 0 2px black);
  }

  &:hover {
    transform: translateY(-50%) scale(1.1);
  }
}

.rightArrow {
  left: unset;
  right: 0;

  & > img {
    margin-left: auto;
    transform: rotate(180deg);
  }
}

.bullets {
  width: 100%;
  height: 4px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 4px;
  padding: 0 4px;

  position: absolute;
  bottom: 6px;

  opacity: 0;
  transition: opacity 0.3s;
}

.bullet {
  height: 100%;
  background: grey;
  border-radius: 4px;
}

.activeBullet {
  background: var(--color-primary);
}
