.containerVideo {
  position: relative;
}

.containerVideoLoading {
  .play,
  .pause {
    display: none;
  }
}

.containerVideo:hover {
  .play,
  .pause {
    visibility: visible;
    -webkit-animation: fadeInFromNone 0.5s ease-out;
    -moz-animation: fadeInFromNone 0.5s ease-out;
    -o-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.loading {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingNone,
.loadingVideo {
  display: none;
}

.pause,
.play {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgb(221, 221, 221);
  background-image: url('../../assets/images/play.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 60%;
  background-size: 50%;

  border-radius: 50%;
  border: rgb(119, 119, 119) 1px solid;
  cursor: pointer;
}

.pause {
  background-image: url('../../assets/images/pause.svg');
  background-position-x: center;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
