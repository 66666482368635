.container {
  width: 100%;
}

.title {
  margin-bottom: 16px;
}

.goHomeBtn {
  border-radius: 4px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.productsContainer {
  display: flex;
  flex-direction: column;

  & > :nth-child(n) {
    margin-bottom: 16px;
  }
}

.noSubscriptionsTitle {
  width: 100%;
  color: #a9a9a9;
  font-weight: bold;
  text-align: center;
  font-size: 28px;
  margin: 40px 0;
}
