.container {
  display: flex;
  align-items: center;
}

.title {
  width: 100px;
  color: rgb(85, 81, 81);
  margin-right: 8px;
}

.select {
  width: 100%;
  max-width: 200px;
  position: relative;
}

.placeholder {
  color: rgb(0, 0, 0, 0.2);
}

.currentValue {
  width: 100%;
  max-width: 200px;
  padding: 7px 3px 5px;

  background: var(--color-background-light_gray);
  border: none;
  border-radius: 4px;
  font-size: 16px;

  cursor: pointer;
}

.options {
  width: 140px;
  display: none;
  position: absolute;
  bottom: -5px;
  transform: translateY(100%);

  border-radius: 4px;
  background: white;
}

.optionsOpen {
  display: block;
  margin-top: 5px;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.16);
}

.option {
  padding: 10px;

  outline: none;
  border-bottom: 1px solid #e5e8eb;

  font-size: 14px;
  color: rgb(63, 62, 62);
  cursor: pointer;

  &:hover {
    color: var(--color-primary);
    border-radius: 4px;
  }
}
