.page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 36px;
}

.message {
  color: var(--color-primary);
}
