*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Default theme colors */
  --color-primary: #0176C6;
  --color-primary_text: #fff;
  --color-background: #F7F7F7;
  --color-background-light_gray: #F3F3F3;
  --product-background_card: #0176C6;
  --color-shade: #F2F2F2;
  --color-border: rgb(213 224 255);
  --color-text: #242427;
  --color-placeholder: #8C8C91;
  --color-red: #FF4D4F;
  --color-blue: #1890FF;
  --color-green: #63ac5b;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);

  --header_height: 50px;
  --footer_height: 68px;
  --max-width: 1360px;
  --border: 1px solid var(--color-border)
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-primary);
}

p, label, input, input:disabled, a, button, b {
  color: var(--color-text);
}

button {
  font-size: 16px;
}

.highcharts-credits {
  display: none;
  opacity: 0;
}

/* rc-slider */

.rc-slider-handle {
  border: none;
  background: var(--color-primary);
  opacity: 1;
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 6px var(--color-primary) !important;
}

.rc-slider-track {
  background: var(--color-primary);
  opacity: 0.4;
}