.container {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0px;
  padding: 10px 20px;
  background: #fff;
  z-index: 2;
}

.logoContainer {
  display: flex;
  margin-right: 40px;
  align-items: center;
}

.logo {
  width: 50px;
  padding: 4px;
  margin-right: 20px;
  border: 1px solid rgb(172, 171, 171);
  border-radius: 10px;
}

.btn {
  height: min-content;
  padding: 8px 20px;
  background: var(--color-primary);
  border: 0;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin-left: auto;
}
